import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Heading from "../components/Heading";
import AuditReportingLogo from "../images/auditReporting-white.svg";
import Audit1 from "../images/Audit-1.svg";
import Audit2 from "../images/Audit-2.svg";
import Audit3 from "../images/Audit-3.svg";
import Audit4 from "../images/Audit-4.svg";
import styles from "./audit.module.scss";

export const query = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "text" }
          slug: { eq: "audit" }
          language: { eq: $language }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            language
          }
          frontmatter {
            title
            date
            header {
              title
              subtitle
              description
            }
            tabs {
              merchant
              user
            }
            sections {
              section {
                id
                title
                abovetitle
                subtitle
                description
              }
            }
          }
        }
      }
    }
  }
`;

const AuditPage = ({ data }) => {
  const pageContent = data.allMarkdownRemark.edges[0].node.frontmatter;
  const { header, sections } = pageContent;
  const section1 = sections.find((e) => e.section.id === "1").section;
  const section2 = sections.find((e) => e.section.id === "2").section;
  const section3 = sections.find((e) => e.section.id === "3").section;
  const section4 = sections.find((e) => e.section.id === "4").section;

  const heading = (
    <Heading>
      <h1>
        <img className="icon" src={AuditReportingLogo} alt="" />
        {header.title}
      </h1>
      <h3>{header.subtitle}</h3>
      <h3>{header.description}</h3>
    </Heading>
  );

  return (
    <Layout heading={heading}>
      <Head title={pageContent.title} />
      <div className={styles.mainContainer}>
        <div className={cx(styles.sectionContainer, styles.sectionFirst)}>
          <div className={styles.sectionPart}>
            <h6>{section1.abovetitle}</h6>
            <h1>{section1.title}</h1>
            <p>{section1.subtitle}</p>
            <p>{section1.description}</p>
          </div>
          <div className={styles.sectionPartImg}>
            <img src={Audit1} alt="Audit1" />
          </div>
        </div>
        <div className={cx(styles.sectionContainer, styles.sectionWrapReverse)}>
          <div className={styles.sectionPartImg}>
            <img src={Audit2} alt="Audit2" />
          </div>
          <div className={styles.sectionPart}>
            <h6>{section2.abovetitle}</h6>
            <h1>{section2.title}</h1>
            <p>{section2.subtitle}</p>
            <p>{section2.description}</p>
          </div>
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionPart}>
            <h6>{section3.abovetitle}</h6>
            <h1>{section3.title}</h1>
            <p>{section3.subtitle}</p>
            <p>{section3.description}</p>
          </div>
          <div className={styles.sectionPartImg}>
            <img src={Audit3} alt="Audit3" />
          </div>
        </div>
        <div className={cx(styles.sectionContainer, styles.sectionWrapReverse)}>
          <div className={styles.sectionPartImg}>
            <img src={Audit4} alt="Audit4" />
          </div>
          <div className={styles.sectionPart}>
            <h6>{section4.abovetitle}</h6>
            <h1>{section4.title}</h1>
            <p>{section4.subtitle}</p>
            <p>{section4.description}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default React.memo(AuditPage);
